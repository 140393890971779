<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-col align="center">
                <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/Ir6etdE0vU8?controls=0&showinfo=0&modestbranding=1&autohide=1"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    mounted() { }
};
</script>

<style scoped>

</style>
