<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-col align="center">
                <a href="https://github.com/jovisaib/godmxgo">
                    <img class="githubcard" src="https://gh-card.dev/repos/jovisaib/godmxgo.svg" />
                </a>
            </v-col>
        </v-row>

        <v-row justify="center" align="center">
            <v-col align="center">
                <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/TIRiJ0a3pFU?controls=0&showinfo=0&modestbranding=1&autohide=1"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </v-col>
        </v-row>

        <v-row justify="center" align="center">
            <v-col align="center">
                <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/ATZq6sw_N-U?controls=0&showinfo=0&modestbranding=1&autohide=1"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    mounted() { }
};
</script>

<style scoped>
.githubcard {
    width: 560px;
    height: auto;
}
</style>
